import React from 'react';
import logolight from './mixfi-full-logo-light-paths-min.svg';
import logodark from './mixfi-full-logo-dark-paths-min.svg';
import './App.css';
// @ts-ignore
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="App">
        <Helmet>
            <title>mixfi</title>
            <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="manifest" href="/site.webmanifest" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </Helmet>

        <img src={logolight} alt="mixfi logo" />
        <h1>Coming soon</h1>
    </div>
  );
}

export default App;
